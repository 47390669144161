import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { AuthProvider } from './contexts/LoginContext';
import { PicklistProvider } from './contexts/PicklistContext';
import { AlertProvider } from './contexts/AlertContext';
import { LoadingProvider } from './contexts/LoadingContext';
import Picklist from './routes/Picklist';
import Login from './routes/Login';
import Home from './routes/Home';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Login />
  },
  {
    path: '/home',
    element: <Home />
  },
  {
    path: '/picklist',
    element: <Picklist />
  }
]);

export default function App() {
  return (
    <LoadingProvider>
      <AuthProvider>
        <PicklistProvider>
          <AlertProvider>
            <RouterProvider router={router} />
          </AlertProvider>
        </PicklistProvider>
      </AuthProvider>
    </LoadingProvider>
  );
}
