import { gql } from '@apollo/client';

export const startPackSession = gql`
  mutation (
    $packer_email: String!
    $member_user_id: Int!
    $location_id: String!
    $order_intent_id: Int
    $order_id: Int
    $rl_vendor: RlVendor!
    $timestamp: TimeStamp!
  ) {
    startPackSession(
      packer_email: $packer_email
      member_user_id: $member_user_id
      location_id: $location_id
      order_intent_id: $order_intent_id
      order_id: $order_id
      rl_vendor: $rl_vendor
      timestamp: $timestamp
    ) {
      id
      packer_email
      member_user_id
      location_id
      order_intent_id
      order_id
      rl_vendor
      started_at
      completed_at
      cancelled_at
    }
  }
`;

export const completePackSession = gql`
  mutation (
    $pack_session_id: Int!
    $timestamp: TimeStamp!
    $products: [ScanSessionProductsInput]!
  ) {
    completePackSession(
      pack_session_id: $pack_session_id
      timestamp: $timestamp
      products: $products
    ) {
      id
      packer_email
      member_user_id
      location_id
      order_intent_id
      rl_vendor
      started_at
      completed_at
      cancelled_at
    }
  }
`;

export const cancelPackSession = gql`
  mutation ($pack_session_id: Int!, $timestamp: TimeStamp!) {
    cancelPackSession(pack_session_id: $pack_session_id, timestamp: $timestamp) {
      id
      packer_email
      member_user_id
      location_id
      order_intent_id
      started_at
      completed_at
      cancelled_at
    }
  }
`;
