import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { RLNrc } from '../constants/RLNrc';
import { useLazyQuery } from '@apollo/client';
import { useLoadingContext } from './LoadingContext';
import { listRlLocations } from '../queries/warehouseAdmin';

interface LoginContextValue {
  email: string | null;
  setEmail: (email: string) => void;
  nrcs: RLNrc[];
  nrc: RLNrc | null;
  setNrc: (nrc: RLNrc | null) => void;
  isLoggedIn: boolean;
}

const LoginContext = createContext<LoginContextValue>({
  email: null,
  setEmail: () => {},
  nrcs: [],
  nrc: null,
  setNrc: () => {},
  isLoggedIn: false
});

export const useLoginContext = () => useContext(LoginContext);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { setLoading } = useLoadingContext();

  const [email, setEmail] = useState<string | null>(null);
  const [nrc, setNrc] = useState<RLNrc | null>(null);
  const [nrcs, setNrcs] = useState<RLNrc[]>([]);
  const loggedIn = useMemo(() => !!email && !!nrc, [email, nrc]);
  const [fetchRLLocations, { loading: listLocationLoading, data: listLocationData }] = useLazyQuery(
    listRlLocations,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (data) {
          setNrcs(data.listRlLocations);
        }
      }
    }
  );

  useEffect(() => {
    setLoading(listLocationLoading);
  }, [listLocationLoading]);

  useEffect(() => {
    fetchRLLocations();
  }, []);

  return (
    <LoginContext.Provider value={{ email, setEmail, nrcs, nrc, setNrc, isLoggedIn: loggedIn }}>
      {children}
    </LoginContext.Provider>
  );
};
