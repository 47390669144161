import { useMemo, useState, useEffect, useCallback } from 'react';
import { Stack, Accordion, AccordionSummary, AccordionDetails, Divider, List } from '@mui/material';
import { DateTime } from 'luxon';
import { useMutation, useLazyQuery } from '@apollo/client';

import { usePicklistContext } from '../../contexts/PicklistContext';
import ProductList from './PicklistProductList';
import { useNavigate } from 'react-router-dom';
import QrScrene from '../../QrScan';
import { useLoadingContext } from '../../contexts/LoadingContext';
import { useAlertContext } from '../../contexts/AlertContext';
import { CompletedScan, ScannedItemsForBackend } from '../../constants/Scan';
import { watchScan } from '../../utilities/zebraScanner';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { omit } from 'lodash';
import FixedBottomNavigation, { BottomNavAction, NavOption } from '../../components/FixedBottomNav';
import {
  getProductFromPickScan,
  getProductFromPickScanResponse,
  ScannedCodeType
} from '../../queries/picklist';
import { completePackSession, cancelPackSession } from '../../queries/packSession';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useLoginContext } from '../../contexts/LoginContext';

const notScanningNavOptions: NavOption[] = [
  {
    label: 'Exit',
    icon: <ExitToAppIcon />,
    action: 'cancel'
  },
  {
    label: 'Scan',
    icon: <QrCodeScannerIcon />,
    action: 'scan'
  },
  {
    label: 'Complete',
    icon: <DoneOutlineIcon />,
    action: 'complete'
  }
];

const scanningNavOptions: NavOption[] = [
  {
    label: 'Close',
    icon: <CancelIcon />,
    action: 'pick'
  }
];

type PicklistState = 'scan' | 'pick';

export default function Picklist() {
  const { setLoading } = useLoadingContext();
  const { isLoggedIn } = useLoginContext();
  const { addAlert } = useAlertContext();
  const { currentPicklist, sessionId, scanCompletedProducts, dispatchFns, userId } =
    usePicklistContext();
  const [scanHandlingInProgress, setScanHandlingInProgress] = useState<boolean>(false);
  const [state, setState] = useState<PicklistState>('pick');

  const { completeProductScan, completeBagScan } = dispatchFns();

  const [bagConfirmation, setBagConfirmation] = useState<boolean>(false);
  const [skippedProductConfirmation, setSkippedProductConfirmation] = useState<boolean>(false);
  const [resolvePickScan, { error: getProductFromPickScanError }] =
    useLazyQuery<getProductFromPickScanResponse>(getProductFromPickScan, {
      fetchPolicy: 'no-cache'
    });
  const [completeSession, { loading: completeSessionLoading, error: completeSessionError }] =
    useMutation(completePackSession);
  const [cancelSession, { loading: cancelSessionLoading, error: cancelSessionError }] =
    useMutation(cancelPackSession);
  const navigate = useNavigate();

  if (!currentPicklist) {
    return null;
  }

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/');
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (getProductFromPickScanError) {
      const title = 'Something went wrong trying to get product ID from scan';
      const message = getProductFromPickScanError.message;
      console.error(`${title}: ${message}`);
      addAlert({
        severity: 'error',
        title,
        message
      });
    }

    if (completeSessionError) {
      const title = 'Something went wrong trying trying to complete session';
      const message = completeSessionError.message;
      console.error(`${title}: ${message}`);
      addAlert({
        severity: 'error',
        title,
        message
      });
    }

    if (cancelSessionError) {
      const title = 'Something went wrong trying trying to cancel session';
      const message = cancelSessionError.message;
      addAlert({
        severity: 'error',
        title,
        message
      });
    }
  }, [getProductFromPickScanError, completeSessionError, cancelSessionError]);

  useEffect(() => {
    setLoading(completeSessionLoading || cancelSessionLoading || scanHandlingInProgress);
  }, [completeSessionLoading, cancelSessionLoading, scanHandlingInProgress]);

  const skippedProductsStr = useMemo(() => {
    // Initialize an object to keep track of all products and their quantities
    const productQuantities = currentPicklist.reduce(
      (acc, product) => {
        acc[product.product_id] = product.product_quantity;
        return acc;
      },
      {} as {
        [product_id: number]: number;
      }
    );

    // Iterate over scanCompletedProducts to subtract scanned products from their total quantities
    Object.values(scanCompletedProducts).forEach((completedScans) => {
      completedScans.forEach((scan) => {
        if (scan.status === 'SCANNED' && scan.product_id !== null) {
          // Ensure the product exists in the productQuantities map before trying to decrement
          if (productQuantities[scan.product_id]) {
            productQuantities[scan.product_id]--;
          }
        }
      });
    });

    // Filter out products that have been fully scanned (quantity of 0)
    const notScannedProducts = Object.fromEntries(
      Object.entries(productQuantities).filter(([_, quantity]) => quantity > 0)
    );

    const amProductsStr: string[] = [];
    const pmProductsStr: string[] = [];

    Object.entries(notScannedProducts).forEach(([productIdStr, quantity]) => {
      const picklistProduct = currentPicklist.find((p) => p.product_id === parseInt(productIdStr));

      if (!picklistProduct) {
        return;
      }

      const productStr = `${picklistProduct.is_oos ? '[OOS] ' : ''}x${quantity} ${
        picklistProduct.product_name
      }`;

      if (picklistProduct.is_am_packed) {
        amProductsStr.push(productStr);
      } else if (!picklistProduct.is_am_packed) {
        pmProductsStr.push(productStr);
      }
    });

    return (
      <>
        <p>Below are skipped products. Are you sure you want to complete this packing session?</p>

        {pmProductsStr.length > 0 && (
          <Accordion>
            <AccordionSummary>PM Products ({pmProductsStr.length})</AccordionSummary>
            <AccordionDetails>
              {pmProductsStr.map((prod, index) => (
                <>
                  <p key={`pm-skipped-product-${index}`}>{prod}</p>
                  {index < pmProductsStr.length - 1 && <Divider />}
                </>
              ))}
            </AccordionDetails>
          </Accordion>
        )}

        {amProductsStr.length > 0 && (
          <Accordion>
            <AccordionSummary>AM Products ({amProductsStr.length})</AccordionSummary>
            <AccordionDetails>
              {amProductsStr.map((prod, index) => (
                <>
                  <p key={`am-skipped-product-${index}`}>{prod}</p>
                  {index < amProductsStr.length - 1 && <Divider />}
                </>
              ))}
            </AccordionDetails>
          </Accordion>
        )}
      </>
    );
  }, [currentPicklist, scanCompletedProducts]);

  const completePacking = () => {
    currentPicklist.forEach((product) => {
      if (
        !scanCompletedProducts[product.product_id] ||
        scanCompletedProducts[product.product_id].length !== product.product_quantity
      ) {
        for (
          let i = scanCompletedProducts[product.product_id]?.length ?? 0;
          i < product.product_quantity;
          i++
        ) {
          scanCompletedProducts[product.product_id] ??= [];
          scanCompletedProducts[product.product_id].push({
            product_id: product.product_id,
            status: 'SKIPPED',
            timestamp: DateTime.now(),
            product_key: '<unused>'
          });
        }
      }
    });

    const completedProducts = Object.values(scanCompletedProducts).reduce(
      (acc: ScannedItemsForBackend[], products: CompletedScan[]) => {
        acc = [...acc, ...products.map((product) => omit(product, 'product_key'))];
        return acc;
      },
      []
    );

    completeSession({
      variables: {
        pack_session_id: sessionId,
        timestamp: DateTime.now(),
        products: completedProducts
      }
    })
      .then(() => {
        addAlert({
          severity: 'success',
          message: `Packing session complete.`
        });
      })
      .catch((error) => {
        const message = 'Something went wrong while completing packing session.';
        console.error(`${message}: ${error.message}`);
        addAlert({
          severity: 'error',
          message
        });
      })
      .finally(() => {
        navigate('/home');
      });
  };

  const cancelPacking = () => {
    if (!confirm('Are you sure you want to cancel?')) {
      return;
    }
    cancelSession({
      variables: {
        pack_session_id: sessionId,
        timestamp: DateTime.now()
      }
    })
      .catch((error) => {
        const message = 'Something went wrong while cancelling packing session.';
        console.error(`${message}: ${error.message}`);
        addAlert({
          severity: 'warning',
          message
        });
      })
      .finally(() => {
        navigate('/home');
      });
  };

  /**
   * This will first check if scan is a duplicate.
   * Then, it will ensure the product is in the picklist.
   * Then, it will check to ensure the product was not scanned too many times.
   * Finally, it will update the scan status of the product.
   *
   * @param scannedProductId
   * @param assetId - is populated for only RL products tracked by Topanga.
   * @returns
   */
  const processScannedProduct = (scannedProductId: number, assetId?: string) => {
    if (assetId) {
      const isDuplicateScan = scanCompletedProducts[scannedProductId]
        ? scanCompletedProducts[scannedProductId].some(
            (scan: CompletedScan) => scan.asset_id === assetId
          )
        : false;
      if (isDuplicateScan) {
        addAlert({
          severity: 'warning',
          message: 'You have already scanned this container'
        });
        return;
      }
    }

    const picklistProduct: any = currentPicklist.find(
      (product) => scannedProductId === product.product_id
    );
    console.log('isPicklistProduct: ', picklistProduct);
    if (!picklistProduct) {
      console.log('warning: scanned not in picklist');
      addAlert({
        severity: 'warning',
        title: 'Incorrect item scanned',
        message: `Scanned item ID:${scannedProductId} is not on your picklist`
      });
      return;
    }
    const scannedCount = scanCompletedProducts[scannedProductId]
      ? scanCompletedProducts[scannedProductId].length
      : 0;
    console.log('scannedCount: ', scannedCount);
    if (scannedCount >= picklistProduct.product_quantity) {
      addAlert({
        severity: 'warning',
        message: `You have already scanned ${picklistProduct.product_name} ${scannedCount} times`
      });
      return;
    }
    completeProductScan(scannedProductId, assetId);
    addAlert({
      severity: 'success',
      message: `${picklistProduct.product_name} is successfully scanned`
    });
  };

  const processsScannedBag = (assetId: string) => {
    const foundScannedBag = scanCompletedProducts.bag
      ? scanCompletedProducts.bag.find((bag: CompletedScan) => bag.asset_id === assetId)
      : null;
    if (!foundScannedBag) {
      completeBagScan(assetId);
      addAlert({
        severity: 'success',
        message: `Bag is successfully scanned`
      });
    } else {
      addAlert({
        severity: 'warning',
        message: 'You have already scanned this bag'
      });
    }
  };

  const handlePickScan = useCallback(
    async (scanResult: string) => {
      if (scanHandlingInProgress) {
        console.log('Handling another scan at the moment...');
        return;
      }
      setState('pick');
      setScanHandlingInProgress(true);

      const getProductIdResponse = await resolvePickScan({
        variables: {
          user_id: userId,
          decoded_scan_value: scanResult
        }
      });

      if (getProductIdResponse.data) {
        const productFromPickScan = getProductIdResponse.data.getProductFromPickScan;

        if (productFromPickScan.__typename === 'BagScanType') {
          processsScannedBag(productFromPickScan.parsed_scan_value);
        } else if (productFromPickScan.__typename === 'ProductScanType') {
          const isRlContainer =
            productFromPickScan.scanned_code_type === ScannedCodeType.ReusableContainer;
          if (isRlContainer) {
            processScannedProduct(
              productFromPickScan.sellable_entity_variation_id,
              productFromPickScan.parsed_scan_value ?? undefined
            );
          } else {
            processScannedProduct(productFromPickScan.sellable_entity_variation_id);
          }
        }
      }

      setScanHandlingInProgress(false);
    },
    [
      scanHandlingInProgress,
      setState,
      setScanHandlingInProgress,
      resolvePickScan,
      userId,
      processScannedProduct,
      processsScannedBag
    ]
  );

  useEffect(() => {
    return watchScan((scanResult: string) => handlePickScan(scanResult));
  }, [handlePickScan]);

  const bagScanned = useMemo(() => {
    return scanCompletedProducts['bag']?.length > 0;
  }, [scanCompletedProducts]);

  const completedPicklist = useMemo(() => {
    return currentPicklist.every(
      (product) =>
        !!scanCompletedProducts[product.product_id] &&
        scanCompletedProducts[product.product_id].length === product.product_quantity
    );
  }, [currentPicklist, scanCompletedProducts]);

  const handleCompletedClick = () => {
    if (!bagScanned) {
      setBagConfirmation(true);
      return;
    }

    if (!completedPicklist) {
      setSkippedProductConfirmation(true);
      return;
    }

    completePacking();
  };

  const handleNavOptionClick = useCallback(
    (navOption: BottomNavAction) => {
      switch (navOption) {
        case 'cancel':
          cancelPacking();
          break;
        case 'pick':
          setState('pick');
          break;
        case 'scan':
          setState('scan');
          break;
        case 'complete':
          handleCompletedClick();
          break;
      }
    },
    [cancelPacking, handleCompletedClick, setState]
  );

  const getBottomNavOptions = useMemo(() => {
    switch (state) {
      case 'pick':
        return notScanningNavOptions;
      case 'scan':
        return scanningNavOptions;
    }
  }, [state]);

  const confirmationModals = useMemo(() => {
    return (
      <>
        <ConfirmationDialog
          title="No Bags Scanned"
          content="There were no bags scanned. Are you sure you want to complete this packing session?"
          open={bagConfirmation}
          setOpen={setBagConfirmation}
          onConfirm={() =>
            completedPicklist ? completePacking() : setSkippedProductConfirmation(true)
          }
        ></ConfirmationDialog>
        <ConfirmationDialog
          title="Skipped Products"
          content={skippedProductsStr}
          open={skippedProductConfirmation}
          setOpen={setSkippedProductConfirmation}
          onConfirm={completePacking}
        ></ConfirmationDialog>
      </>
    );
  }, [bagConfirmation, skippedProductConfirmation, skippedProductsStr]);

  const content = useMemo(() => {
    switch (state) {
      case 'pick':
        return (
          <>
            <ProductList />
          </>
        );
    }
  }, [state]);

  const scanning = useMemo(() => {
    return state === 'scan' ? (
      <QrScrene
        onClose={() => setState('pick')}
        onScan={(scanResult) => handlePickScan(scanResult)}
      />
    ) : null;
  }, [state]);

  return (
    <>
      <FixedBottomNavigation navOptions={getBottomNavOptions} onClick={handleNavOptionClick} />
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        p={2}
        sx={{ overflowY: 'scroll' }}
        mb="75px"
      >
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>{content}</List>
      </Stack>
      {scanning}
      {confirmationModals}
    </>
  );
}
