import { gql } from '@apollo/client';

export const getPicklistByTopangaUserId = gql`
  query ($order_id: Int) {
    getPicklistByTopangaUserIdHash(order_id: $order_id) {
      picklist {
        product_id
        product_name
        product_quantity
        warehouse_location
        is_am_packed
        is_oos
      }
      user_id
      order_intent_id
      order_id
      delivery_date
    }
  }
`;

export enum ScannedCodeType {
  ReusableContainer = 'ReusableContainer',
  PdpUrl = 'PdpUrl',
  ProductUpc = 'ProductUpc'
}

type ResolvedPickScanProduct =
  | {
      __typename: 'BagScanType';
      parsed_scan_value: string;
    }
  | {
      __typename: 'ProductScanType';
      scanned_code_type: ScannedCodeType;
      parsed_scan_value: string;
      sellable_entity_variation_id: number;
    };

export type getProductFromPickScanResponse = {
  getProductFromPickScan: ResolvedPickScanProduct;
};

export const getProductFromPickScan = gql`
  query ($decoded_scan_value: String!, $user_id: Int!) {
    getProductFromPickScan(decoded_scan_value: $decoded_scan_value, user_id: $user_id) {
      ... on BagScanType {
        parsed_scan_value
      }
      ... on ProductScanType {
        parsed_scan_value
        sellable_entity_variation_id
        scanned_code_type
      }
    }
  }
`;
