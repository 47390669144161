import { useState, useCallback, useEffect, useContext } from 'react';
import { DateTime } from 'luxon';
import Button from '@mui/material/Button';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import QrScrene from '../../QrScan';
import { usePicklistContext } from '../../contexts/PicklistContext';
import { Stack } from '@mui/material';
import removeHttp from '../../utilities/removeHttp';
import { useLoadingContext } from '../../contexts/LoadingContext';
import { useAlertContext } from '../../contexts/AlertContext';
import { watchScan } from '../../utilities/zebraScanner';
import { PicklistContainer } from '../../constants/Picklist';
import { useLoginContext } from '../../contexts/LoginContext';
import { getPicklistByTopangaUserId } from '../../queries/picklist';
import { startPackSession } from '../../queries/packSession';

interface GetPicklistByTopangaUserIdResponse {
  getPicklistByTopangaUserIdHash: PicklistContainer;
}

const ROUNDS_1RL_URL_PREFIX = '1rl.us/refill?order_id=';

export default function Home() {
  const navigate = useNavigate();
  const { email, nrc, setEmail, setNrc, isLoggedIn } = useLoginContext();
  const { setLoading } = useLoadingContext();
  const { addAlert } = useAlertContext();
  const [picklistScanOpen, setPicklistScanOpen] = useState(false);
  const [
    getPicklist,
    { loading: getPicklistLoading, error: getPicklistError, data: getPicklistData }
  ] = useLazyQuery<GetPicklistByTopangaUserIdResponse>(getPicklistByTopangaUserId);
  const [
    startSession,
    { loading: startSessionLoading, error: startSessionError, data: startSessionData }
  ] = useMutation(startPackSession);
  const {
    setUserId,
    setSessionId,
    clearPicklist,
    setCurrentPickListOrderId,
    setCurrentPickListDeliveryDate,
    setCurrentPicklist
  } = usePicklistContext();

  const openPicklistScan = () => setPicklistScanOpen(true);
  const closePicklistScan = () => setPicklistScanOpen(false);

  useEffect(() => {
    if (!isLoggedIn) {
      console.log('User is missing login state. Logging out.', email, nrc);
      logout();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (getPicklistError) {
      const title = 'Something went wrong while getting picklist';
      const message = getPicklistError.message;
      console.error(`${title}: ${message}`);
      addAlert({
        severity: 'error',
        title,
        message
      });
    }

    if (startSessionError) {
      const title = 'Something went wrong trying to start a packing session';
      const message = startSessionError.message;
      console.error(`${title}: ${message}`);
      addAlert({
        severity: 'error',
        title,
        message
      });
    }
  }, [getPicklistError, startSessionError]);

  useEffect(() => {
    if (getPicklistData?.getPicklistByTopangaUserIdHash) {
      const {
        user_id: userId,
        order_intent_id: orderIntentId,
        order_id: orderId,
        delivery_date: deliveryDate,
        picklist
      } = getPicklistData.getPicklistByTopangaUserIdHash;
      console.log(userId, picklist, orderIntentId);
      if (userId && picklist) {
        clearPicklist();
        addAlert({
          severity: 'success',
          message: `Picklist retrieve for user ID ${userId}`
        });
        setUserId(userId);
        setCurrentPicklist(picklist);
        if (orderId) setCurrentPickListOrderId(orderId);
        if (deliveryDate) setCurrentPickListDeliveryDate(deliveryDate);
        void startSession({
          variables: {
            packer_email: email,
            member_user_id: userId,
            location_id: nrc?.id,
            rl_vendor: nrc?.vendor,
            order_intent_id: orderIntentId || null,
            order_id: orderId || null,
            timestamp: DateTime.now()
          }
        });
      } else {
        const message = 'Something went wrong trying to get picklist';
        console.error(message);
        addAlert({
          severity: 'error',
          message
        });
      }
    }
  }, [getPicklistData]);

  useEffect(() => {
    console.log('startSessionData', startSessionData);
    if (startSessionData?.startPackSession) {
      setSessionId(startSessionData.startPackSession.id);
      navigate('/picklist');
    }
  }, [startSessionData]);

  useEffect(() => {
    return watchScan(handleScanPicklist);
  }, []);

  const handleScanPicklist = useCallback((scanResult: string) => {
    scanResult = removeHttp(scanResult);

    if (scanResult.startsWith(ROUNDS_1RL_URL_PREFIX)) {
      const prefixLength = ROUNDS_1RL_URL_PREFIX.length;
      const withoutPrefix = scanResult.slice(prefixLength);
      void getPicklist({ variables: { order_id: +withoutPrefix } });
    } else {
      addAlert({
        severity: 'error',
        title: 'Invalid Picklist Scan',
        message: 'Scan is not recognized as a valid Picklist QR code'
      });
    }

    closePicklistScan();
  }, []);

  const logout = () => {
    setEmail('');
    setNrc(null);
    navigate('/');
  };

  useEffect(() => {
    setLoading(getPicklistLoading || startSessionLoading);
  }, [getPicklistLoading, startSessionLoading]);

  return (
    <>
      <Stack p={2} spacing={2}>
        {!picklistScanOpen && (
          <Button variant="contained" onClick={openPicklistScan}>
            Scan Picklist
          </Button>
        )}
        <Button variant="outlined" color="error" onClick={logout}>
          Log Out
        </Button>
      </Stack>
      {picklistScanOpen && (
        <QrScrene onClose={() => setPicklistScanOpen(false)} onScan={handleScanPicklist} />
      )}
    </>
  );
}
