import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useMemo } from 'react';
import { usePicklistContext } from '../../contexts/PicklistContext';
import { Product } from '../../constants/Picklist';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ProductList from './ProductList/ProductList';

export default function PicklistProductList() {
  const {
    currentPicklist,
    scanCompletedProducts,
    dispatchFns,
    currentOrderId,
    currentDeliveryDate
  } = usePicklistContext();

  const { clearBagScans } = dispatchFns();

  const amProducts = useMemo(
    () => currentPicklist.filter((product: Product) => product.is_am_packed),
    [currentPicklist]
  );
  const pmProducts = useMemo(
    () => currentPicklist.filter((product: Product) => !product.is_am_packed),
    [currentPicklist]
  );

  const numberOfAmProducts = useMemo(
    () => amProducts.reduce((acc, product) => acc + product.product_quantity, 0),
    [amProducts]
  );
  const numberOfPmProducts = useMemo(
    () => pmProducts.reduce((acc, product) => acc + product.product_quantity, 0),
    [pmProducts]
  );

  return (
    <>
      <h4 style={{ marginTop: 0, marginBottom: '12px' }}>{`Delivery Date: ${
        currentDeliveryDate ? currentDeliveryDate : ''
      }`}</h4>
      <p style={{ marginTop: '2px', marginBottom: '8px' }}>{`Order ID: ${
        currentOrderId ? currentOrderId : ''
      }`}</p>
      <div>
        {pmProducts.length > 0 && (
          <Accordion defaultExpanded>
            <AccordionSummary sx={{ margin: 0 }}>
              PM Products ({numberOfPmProducts})
            </AccordionSummary>
            <AccordionDetails>
              <ProductList products={pmProducts} />
            </AccordionDetails>
          </Accordion>
        )}
        {amProducts.length > 0 && (
          <Accordion defaultExpanded>
            <AccordionSummary>AM Products ({numberOfAmProducts})</AccordionSummary>
            <AccordionDetails>
              <ProductList products={amProducts} />
            </AccordionDetails>
          </Accordion>
        )}
      </div>
      <ListItem
        secondaryAction={
          Array.isArray(scanCompletedProducts.bag) &&
          scanCompletedProducts.bag.length > 0 && (
            <Button size="small" onClick={clearBagScans}>
              Clear
            </Button>
          )
        }
      >
        <ListItemText
          primary="Bag"
          secondary={
            <Typography variant="caption">
              Number of bags scanned:{' '}
              {Array.isArray(scanCompletedProducts.bag) ? scanCompletedProducts.bag.length : 0}
            </Typography>
          }
        />
      </ListItem>
    </>
  );
}
