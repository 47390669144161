import {
  createContext,
  Dispatch,
  ReactNode,
  useCallback,
  useContext,
  useReducer,
  useState
} from 'react';
import { Product } from '../constants/Picklist';
import { CompletedScan } from '../constants/Scan';
import { ScanAction } from '../constants/ScanAction';
import { DateTime } from 'luxon';
import { omit } from 'lodash';
import getProductKey from '../utilities/getProductKey';

interface PicklistContextValue {
  currentOrderId: number | null;
  setCurrentPickListOrderId: (orderId: number) => void;
  currentDeliveryDate: Date | null;
  setCurrentPickListDeliveryDate: (deliveryDate: any) => void;
  currentPicklist: Product[];
  setCurrentPicklist: (picklist: Product[]) => void;
  userId: number | null;
  setUserId: (userId: number) => void;
  sessionId: number | null;
  setSessionId: (sessionId: number) => void;
  scanCompletedProducts: Record<number | string, CompletedScan[]>;
  clearPicklist: () => void;
  dispatchFns: (dispatch?: Dispatch<ScanAction>) => {
    completeProductScan: (productId: number, assetId?: string) => void;
    skipProductScan: (productId: number, index?: number) => void;
    unskipProductScan: (productId: number, index?: number) => void;
    clearProductScan: (productId: number, index: number) => void;
    completeBagScan: (assetId: string) => void;
    clearBagScans: () => void;
  };
}

const PicklistContext = createContext<PicklistContextValue>({
  currentOrderId: null,
  setCurrentPickListOrderId: () => {},
  currentDeliveryDate: null,
  setCurrentPickListDeliveryDate: () => {},
  currentPicklist: [],
  setCurrentPicklist: () => {},
  userId: null,
  setUserId: () => {},
  sessionId: null,
  setSessionId: () => {},
  scanCompletedProducts: {},
  clearPicklist: () => {},
  dispatchFns: () => ({
    completeProductScan: () => {},
    skipProductScan: () => {},
    unskipProductScan: () => {},
    clearProductScan: () => {},
    completeBagScan: () => {},
    clearBagScans: () => {}
  })
});

export const usePicklistContext = () => useContext(PicklistContext);

const safelyPushItem = (
  newState: Record<number | string, CompletedScan[]>,
  item: CompletedScan
) => {
  if (item.product_id === undefined || item.product_id === null) {
    const message = 'No product ID in item. Cannot add to state.';
    console.warn(message);
    return;
  }
  if (
    newState[item.product_id!].filter((product: CompletedScan) => {
      return product.product_key === item.product_key;
    }).length > 0
  ) {
    const message = 'Product item with same product key already exists in state store.';
    console.error(message);
    return;
  }
  newState[item.product_id].push(item);
  return newState;
};

const findVacantProductKey = (
  scanState: Record<number | string, CompletedScan[]>,
  productId: number
) => {
  let productKey = '';
  let index = -1;
  do {
    index += 1;
    productKey = getProductKey(productId, index);
  } while (scanState[productId].some((product) => product.product_key === productKey));
  return productKey;
};

// TODO: Abstract out each action to make this more readable
function reducer(
  state: Record<number | string, CompletedScan[]>,
  action: ScanAction
): Record<number | string, CompletedScan[]> {
  const newState = { ...state };
  console.log('reducer', action.type, state);

  switch (action.type) {
    case 'SCANNED':
      if (action.product_id) {
        if (!newState[action.product_id]) {
          newState[action.product_id] = [];
        }
        const productKey = findVacantProductKey(newState, action.product_id);
        safelyPushItem(newState, {
          product_id: action.product_id,
          asset_id: action.asset_id,
          status: 'SCANNED',
          timestamp: DateTime.now(),
          product_key: productKey
        });
      }
      return newState;
    case 'SKIPPED':
      if (action.product_id) {
        if (!newState[action.product_id]) {
          newState[action.product_id] = [];
        }
        const productKey = !!action.index
          ? getProductKey(action.product_id, action.index)
          : findVacantProductKey(newState, action.product_id);
        safelyPushItem(newState, {
          product_id: action.product_id,
          status: 'SKIPPED',
          timestamp: DateTime.now(),
          product_key: productKey
        });
      }
      return newState;
    case 'UNSKIPPED':
      console.log('unskipping...');
      if (action.product_id) {
        if (newState[action.product_id]) {
          console.log('length', newState[action.product_id].length);
          if (newState[action.product_id].length === 1) {
            return omit(newState, action.product_id);
          }
          const index =
            action.index !== undefined
              ? action.index
              : newState[action.product_id].findIndex((product) => product.status === 'SKIPPED');
          const productKey = getProductKey(action.product_id, index);
          console.log('Unskipping product key', productKey);

          const skipped: CompletedScan[] = newState[action.product_id].filter(
            (product: CompletedScan) =>
              product.status === 'SKIPPED' && product.product_key != productKey
          );

          const scanned: CompletedScan[] = newState[action.product_id].filter(
            (product: CompletedScan) => product.status === 'SCANNED'
          );

          newState[action.product_id] = [...scanned, ...skipped];

          return newState;
        }
      }
      return newState;
    case 'SCANNED_BAG':
      if (action.asset_id) {
        if (!newState.bag) {
          newState.bag = [];
        }
        const index = newState.bag.length;
        newState.bag.push({
          product_id: null,
          status: 'SCANNED',
          asset_id: action.asset_id,
          timestamp: DateTime.now(),
          product_key: getProductKey(0, index)
        });
      }
      return newState;
    case 'CLEAR_BAG_SCANS':
      newState.bag = [];
      return newState;
    case 'CLEAR_PRODUCT_SCAN':
      if (action.product_id) {
        const productKey = getProductKey(action.product_id, action.index!);
        return {
          ...newState,
          [action.product_id]: newState[action.product_id].filter(
            (product) => product.product_key !== productKey
          )
        };
      }
      return state;
    case 'CLEAR_PICKLIST':
      return {};
    default:
      throw new Error();
  }
}

export const PicklistProvider = ({ children }: { children: ReactNode }) => {
  // TODO: Combine some of these states
  const [currentOrderId, setCurrentOrderId] = useState<number | null>(null);
  const [userId, setUserId] = useState<number | null>(null);
  const [sessionId, setSessionId] = useState<number | null>(null);
  const [currentDeliveryDate, setCurrentDeliveryDate] = useState<any>(null);

  const [picklist, setPicklist] = useState<any[]>([]);

  const [state, dispatch] = useReducer(reducer, {});

  const dispatchFns = useCallback(
    (_dispatch = dispatch) => {
      function completeProductScan(productId: number, assetId?: string) {
        _dispatch({ type: 'SCANNED', product_id: productId, asset_id: assetId });
      }

      function skipProductScan(productId: number, index?: number) {
        _dispatch({ type: 'SKIPPED', product_id: productId, index });
      }

      function unskipProductScan(productId: number, index?: number) {
        _dispatch({ type: 'UNSKIPPED', product_id: productId, index });
      }

      function clearProductScan(productId: number, index: number) {
        _dispatch({ type: 'CLEAR_PRODUCT_SCAN', product_id: productId, index });
      }

      function completeBagScan(assetId: string) {
        _dispatch({ type: 'SCANNED_BAG', asset_id: assetId });
      }

      function clearBagScans() {
        _dispatch({ type: 'CLEAR_BAG_SCANS' });
      }

      return {
        completeProductScan,
        skipProductScan,
        unskipProductScan,
        clearProductScan,
        completeBagScan,
        clearBagScans
      };
    },
    [dispatch]
  );

  const clearPicklist = useCallback(() => {
    setPicklist([]);
    setCurrentOrderId(null);
    setCurrentDeliveryDate(null);
    setSessionId(null);
    dispatch({ type: 'CLEAR_PICKLIST' });
  }, [setPicklist, setCurrentOrderId, setCurrentDeliveryDate, setSessionId, dispatch]);

  return (
    <PicklistContext.Provider
      value={{
        currentPicklist: picklist,
        setCurrentPicklist: setPicklist,
        currentOrderId,
        setCurrentPickListOrderId: setCurrentOrderId,
        currentDeliveryDate,
        setCurrentPickListDeliveryDate: setCurrentDeliveryDate,
        userId,
        setUserId,
        sessionId,
        setSessionId,
        scanCompletedProducts: state,
        dispatchFns,
        clearPicklist
      }}
    >
      {children}
    </PicklistContext.Provider>
  );
};
