import { usePicklistContext } from '../../../contexts/PicklistContext';
import { Product } from '../../../constants/Picklist';
import getProductKey from '../../../utilities/getProductKey';
import ProductItem from './ProductItem';
import { ScanStatus } from '../../../constants/Scan';
import { useCallback, useMemo } from 'react';

export default function ProductList({ products }: { products: Product[] }) {
  const { scanCompletedProducts, dispatchFns } = usePicklistContext();
  const { skipProductScan, unskipProductScan, clearProductScan } = dispatchFns();

  const getScanStatus = useCallback(
    (isOos: boolean, status: ScanStatus = 'INCOMPLETE'): ScanStatus => {
      // Give SCANNED priority over OOS to allow for scanning of OOS products
      if (status === 'SCANNED') {
        return 'SCANNED';
      }
      if (isOos) {
        return 'OUT_OF_STOCK';
      }
      if (status === 'SKIPPED') {
        return 'SKIPPED';
      }
      return 'INCOMPLETE';
    },
    []
  );

  const productsToRender = useMemo(
    () =>
      products.reduce((acc: JSX.Element[], product: Product, productIndex) => {
        for (let i = 0; i < product.product_quantity; i++) {
          const key = getProductKey(product.product_id || 0, i);
          const foundProducts = scanCompletedProducts[product.product_id]?.find(
            (scannedProduct) => scannedProduct.product_key === key
          );
          const scannedStatus = foundProducts?.status;
          acc.push(
            <ProductItem
              product={product}
              productKey={key}
              key={key}
              index={i}
              isLast={products.length - 1 === productIndex}
              skipProductScan={skipProductScan}
              unskipProductScan={unskipProductScan}
              scanStatus={getScanStatus(product.is_oos, scannedStatus)}
              clearProductScan={clearProductScan}
            />
          );
        }
        return acc;
      }, []),
    [
      products,
      scanCompletedProducts,
      getScanStatus,
      skipProductScan,
      unskipProductScan,
      clearProductScan
    ]
  );

  return <>{productsToRender.map((product) => product)}</>;
}
