import { gql } from '@apollo/client';

export const listRlLocations = gql`
  query listRlLocations {
    listRlLocations {
      id
      name
      vendor
    }
  }
`;

export const getPickPackUserGql = gql`
  query getPickPackUser($email: String!) {
    getPickPackUser(email: $email) {
      email
    }
  }
`;
