import { useMemo } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import HideSource from '@mui/icons-material/HideSource';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Product } from '../../../constants/Picklist';
import { ScanStatus } from '../../../constants/Scan';
import { noop } from 'lodash';

interface ProductCompleteIconProps {
  scanStatus: ScanStatus;
}

function ProductCompleteIcon({ scanStatus }: ProductCompleteIconProps) {
  if (scanStatus === 'SCANNED') {
    return <CheckCircleIcon style={{ color: 'green' }} />;
  }
  if (scanStatus === 'SKIPPED') {
    return <HideSource style={{ color: 'orange' }} />;
  }
  if (scanStatus === 'OUT_OF_STOCK') {
    return <HighlightOffIcon style={{ color: 'red' }} />;
  }
  return <HighlightOffIcon />;
}

interface ProductItemProps {
  productKey: string;
  product: Product;
  index: number;
  isLast: boolean;
  skipProductScan: (productId: number, index: number) => void;
  unskipProductScan: (productId: number, index: number) => void;
  scanStatus: ScanStatus;
  clearProductScan: (productId: number, index: number) => void;
}

export default function ProductItem({
  product,
  productKey,
  index,
  isLast,
  skipProductScan,
  unskipProductScan,
  scanStatus,
  clearProductScan
}: ProductItemProps) {
  if (!product) {
    return null;
  }

  const getProductItemContent = (product: Product) => {
    return (
      <>
        <Typography variant="body2">{product.warehouse_location}</Typography>
        <Typography variant="body2">ID: {product.product_id}</Typography>
        <Typography variant="body2">{productKey}</Typography>
      </>
    );
  };

  const handleClearClicked = () => {
    clearProductScan(product.product_id, index);
  };

  const getListItemAction = useMemo(() => {
    if (scanStatus === 'INCOMPLETE') {
      return (
        <Button size="small" onClick={() => skipProductScan(product.product_id, index)}>
          skip
        </Button>
      );
    }

    if (scanStatus === 'SKIPPED') {
      return (
        <Button size="small" onClick={() => unskipProductScan(product.product_id, index)}>
          unskip
        </Button>
      );
    }

    if (scanStatus === 'SCANNED') {
      return (
        <Button size="small" onClick={handleClearClicked}>
          clear
        </Button>
      );
    }

    if (scanStatus === 'OUT_OF_STOCK') {
      return (
        <Button size="small" onClick={noop} sx={{ color: 'red', fontWeight: 'bold' }} disableRipple>
          OOS
        </Button>
      );
    }
  }, [scanStatus]);

  return (
    <Stack>
      <ListItem sx={{ paddingTop: '12px', paddingBottom: '12px' }} disableGutters disablePadding>
        <ListItemIcon>
          <ProductCompleteIcon key={productKey} scanStatus={scanStatus} />
        </ListItemIcon>

        <ListItemText
          primary={product.product_name}
          secondary={<Stack>{getProductItemContent(product)}</Stack>}
        />
        {getListItemAction}
      </ListItem>
      {!isLast && <Divider variant="fullWidth" component="li" />}
    </Stack>
  );
}
